
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import NetworksalesHistory from "@/components/networksales/NetworksalesHistory.vue";

export default defineComponent({
  name: "networksales",
  components: {
    NetworksalesHistory
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("networlsalesTab") || "0");

      setCurrentPageTitle("Network Sales");
    });
  }
});
