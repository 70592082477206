
import { defineComponent } from "vue";
import pagination from 'vue-bootstrap-pagination'
import {networksaleshistory}  from "@/api/networksales";


export default defineComponent({
  name: "networksaleshistory", 
  methods: {
    async networksaleshistory(){ 
      const response = await networksaleshistory(1);
       this.list = response.data.downlinesSales;
      console.log(response);
    },
    
  },
  mounted(){
      this.networksaleshistory();
  },
  data(){
      return { 
          list : []
      }
  }, 
  components: {
  },
  props: {
    widgetClasses: String
  }
});
